<template>
  <b-row class="order-detail-content">
    <b-col :cols="12">
      <div class="d-flex justify-content-between align-items-center">
        <h4>Order Detail</h4>
        <b-icon icon="x" scale="2" @click="hideOrderDetail" style="cursor: pointer;"></b-icon>
      </div>
      <b-row class="">
        <b-col :cols="12">
          <b-form-group label="Order ID" label-cols="6" label-cols-sm="5" class="align-items-center">
            <div style="height: 100%; " class="d-flex align-items-center">{{ currentOrder.id }}</div>
          </b-form-group>
        </b-col>
        <b-col :cols="12">
          <b-form-group label="Card BIN" label-cols="6" label-cols-sm="5" class="align-items-center"
            v-if="currentIndex == 1">
            <div style="height: 100%; " class="d-flex align-items-center">{{ currentOrder.cardBin }}</div>
          </b-form-group>
          <b-form-group label="Card No" label-cols="6" label-cols-sm="5" class="align-items-center"
            v-if="currentIndex == 4">
            <div style="height: 100%; " class="d-flex align-items-center">{{ currentOrder.cardNo }}</div>
          </b-form-group>
        </b-col>
        <b-col :cols="12">
          <b-form-group label="Recharge Amount" label-cols="6" label-cols-sm="5" class="align-items-center">
            <div style="height: 100%; " class="d-flex align-items-center">$ {{ currentOrder.rechargeAmount }}</div>
          </b-form-group>
        </b-col>
        <b-col :cols="12" v-if="currentIndex == 1">
          <b-form-group label="Card Fee" label-cols="6" label-cols-sm="5" class="align-items-center">
            <div style="height: 100%; " class="d-flex align-items-center">$ {{ currentOrder.cardFee }}</div>
          </b-form-group>
        </b-col>
        <b-col :cols="12">
          <b-form-group label="Service Fee" label-cols="6" label-cols-sm="5" class="align-items-center">
            <div style="height: 100%; " class="d-flex align-items-center">
              $ {{ currentOrder.serviceFee }}
            </div>
          </b-form-group>
        </b-col>
        <b-col :cols="12">
          <b-form-group label="Total Amount" label-cols="6" label-cols-sm="5" class="align-items-center">
            <div style="height: 100%; " class="d-flex align-items-center">$ {{ currentOrder.totalAmount }}</div>
          </b-form-group>
        </b-col>
        <b-col :cols="12">
          <b-form-group label="USDT Rate" label-cols="6" label-cols-sm="5" class="align-items-center">
            <div style="height: 100%; " class="d-flex align-items-center">{{ currentOrder.usdtRate }}</div>
          </b-form-group>
        </b-col>
        <b-col :cols="12">
          <b-form-group label="Pay Amount" label-cols="6" label-cols-sm="5" class="align-items-center">
            <div style="height: 100%; " class="d-flex align-items-center">USDT {{ currentOrder.payUsdtAmount }}
            </div>
          </b-form-group>
        </b-col>
        <b-col :cols="12">
          <b-form-group label="Status" label-cols="6" label-cols-sm="5" class="align-items-center">
            <div style="height: 100%; " class="d-flex align-items-center">{{ statusFormatts(currentOrder.status) }}
            </div>
          </b-form-group>
        </b-col>
        <b-col :cols="12">
          <b-form-group label="Create Time" label-cols="6" label-cols-sm="5" class="align-items-center">
            <div style="height: 100%; " class="d-flex align-items-center">{{ currentOrder.createTime }}</div>
          </b-form-group>
        </b-col>
        <b-col :cols="12">
          <b-form-group label="Expire Date" label-cols="6" label-cols-sm="5" class="align-items-center">
            <div style="height: 100%; " class="d-flex align-items-center">{{ currentOrder.expireDate }}</div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    props: {
      currentOrder: {
        type: Object,
        default: () => {
          return {}
        },
      },
      currentIndex: {
        type: Number,
        default: 1
      },
    },
    methods: {
      hideOrderDetail() {
        this.$emit('hideOrderDetail')
      },
      statusFormatts(val, key, data) {
        switch (val) {
          case 0:
            return 'To be paid';
          case 1:
            return 'Paid';
          case 2:
            if (this.currentIndex == 1) return 'Card opening';
            if (this.currentIndex == 4) return 'Recharging';
            return val;
          case 3:
            if (this.currentIndex == 1) return 'Card opening succeeded';
            if (this.currentIndex == 4) return 'Recharge succeeded';
            return val;
          case 4:
            if (this.currentIndex == 1) return 'Card opening failed';
            if (this.currentIndex == 4) return 'Recharge failed';
            return val;
          case 5:
            return 'Cancelled';
          default:
            return val;
        }
      },
    }
  }
</script>

<style>
</style>
