<template>
  <div class="container setting_container" >
    <b-row>
      <b-col :md="4" :lg="3" class="d-md-block" :class="{'d-none': hideLBtns }">
        <h3>Setting</h3>
        <div class="left_btns">
          <b-button variant="light" block :class="{'btn_active':currentIndex === 0}" @click="btnIndex(0 , 'Profile')">
            Profile</b-button>
          <b-button variant="light" block :class="{'btn_active':currentIndex === 1}"
            @click="btnIndex(1 , 'My card opening record')">
            My card opening record</b-button>
          <b-button variant="light" block :class="{'btn_active':currentIndex === 4}"
            @click="btnIndex(4 , 'My Recharge record')">
            My Recharge record</b-button>
          <b-button variant="light" block :class="{'btn_active':currentIndex === 2}"
            @click="btnIndex(2 ,'Password Reset')">Password Reset</b-button>
          <b-button variant="light" block :class="{'btn_active':currentIndex === 3}" v-if="emailAuth === 0"
            @click="btnIndex(3 , 'Email Authentication')">Email Authentication</b-button>
          <b-button variant="light" block :class="{'btn_active':currentIndex === 5}" v-if="!profileInfo.upgradeCode"
            @click="btnIndex(5 , 'Upgrade Code')">Upgrade Code</b-button>
        </div>
      </b-col>
      <b-col :md="8" :lg="9" v-if="hideLBtns">
        <div class="d-block d-md-none d-flex justify-content-between align-items-center">
          <div>
            <h5 @click="backBtn" style="cursor: pointer;"><i class="icon icon-arrowleft"></i>Back</h5>
          </div>
          <div>
            <h3 class="text-right">{{ currentTitle }}</h3>
          </div>
        </div>
        <!-- Profile -->
        <div v-if="currentIndex === 0">
          <h3 class="ml-3 d-none d-md-block">{{ currentTitle }}</h3>
          <b-alert class="ml-3 mr-3" :show="showinfoAlert" dismissible @dismissed="showinfoAlert=0" variant="success">
            {{ successinfoMsg }}
          </b-alert>
          <b-row class="user_info m-1 align-items-center">
            <b-col :cols="12" sm="4" offset-sm="0" class="d-flex justify-content-center mb-3">
              <!-- <div class="avatar_img"></div> -->
              <b-img center fluid src="../../assets/logo3.png" ></b-img>
            </b-col>
            <b-col :cols="12" sm="8">
              <b-form-group label-cols="3" label-cols-sm="3" label-cols-lg="2" label="Name">
                <b-form-input v-model="profileInfo.nickName"></b-form-input>
              </b-form-group>
              <b-form-group label-cols="3" label-cols-sm="3" label-cols-lg="2" label="Email">
                <b-form-input v-model="profileInfo.email" disabled></b-form-input>
              </b-form-group>
              <!-- <b-form-group label-cols="12" label="USDT TCR20 Address">
                <b-form-input v-model="profileInfo.usdtAddress"></b-form-input>
              </b-form-group> -->
              <b-button variant="primary" block @click="editUserInfo">Save</b-button>
            </b-col>
          </b-row>
          <div class="user_info_progress m-3">
            <h5 style="font-weight: bolder;">Card Capacity Limit</h5>
            <div>
              <div class="mb-1">Capacity: {{ profileInfo.cardCapacity }} / {{ profileInfo.maxCapacity }}</div>
              <b-progress :value="profileInfo.cardCapacity" :max="profileInfo.maxCapacity" class="mb-3"></b-progress>
            </div>
            <div v-if="profileInfo.upgradeCode">
              <h5 style="font-weight: bolder;">Share Link <b-button variant="link" @click="copyShareLink" v-if="profileInfo.shareCode">Copy</b-button></h5>
              <b-button variant="success" block v-if="!profileInfo.shareCode" @click="applyShareLink">Apply for share link</b-button>
              <div v-else>{{shareLink + profileInfo.shareCode}}</div>
            </div>
            <div class="pt-3" v-if="profileInfo.shareCode">
              <h5 style="font-weight: bolder;">Total share link registered users {{shareUserList.length}}</h5>
              <ul class="list-inline">
                <li class="list-inline-item mb-2" v-for="u in shareUserList">
                  <b-card no-body class="p-2 text-center" bg-variant="info" text-variant="white">
                    <b-card-text>
                      <div>{{u.userName}}</div>
                      <div style="font-size: small">{{u.createTime}}</div>
                    </b-card-text>
                  </b-card>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <div v-if="currentIndex === 2">
          <h3 class="ml-3 d-none d-md-block">{{ currentTitle }}</h3>
          <b-row>
            <b-col :cols="12" :md="10" :lg="6">
              <div class="m-3">
                <b-form @submit="confirmPsw">
                  <b-form-group label="Old Password">
                    <b-form-input v-model="passwordForm.oldPassword" type="password" placeholder="Enter old password" required></b-form-input>
                  </b-form-group>
                  <b-form-group label="New Password">
                    <b-form-input v-model="passwordForm.newPassword" type="password" placeholder="Enter new password" :state="valiPassword" required></b-form-input>
                    <b-form-invalid-feedback :state="valiPassword">
                      Password length must be between 5 and 20
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group label="Confirm Password">
                    <b-form-input v-model="passwordForm.confirmPassword" type="password" placeholder="Confirm password" :state="validationPassword" required></b-form-input>
                    <b-form-invalid-feedback :state="validationPassword">
                      The two passwords are inconsistent
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-button variant="primary" :disabled="!valiPassword || !validationPassword" block class="mt-3" type="submit">Reset Password</b-button>
                </b-form>
                
              </div>
            </b-col>
          </b-row>
        </div>

        <div v-if="currentIndex === 3 && emailAuth === 0">
          <h3 class="ml-3 d-none d-md-block">{{ currentTitle }}</h3>
          <b-row>
            <b-col :cols="12" :md="10" :lg="6">
              <div class="m-3 confirm_code">
                <b-alert :show="showAlert" variant="success">{{ successMsg }}</b-alert>
                <b-alert :show="showErrorAlert" variant="danger">{{ errorMsg }}</b-alert>
                <b-form-input v-model="profileInfo.code" placeholder="Enter code"></b-form-input>
                <div class="text-center text">Please check your email for the code.</div>
                <b-button variant="primary" block class="mt-3" @click="checkCodeClick">Verify</b-button>
                <b-button variant="outline-light" style="color: #333;" block class="mt-3" @click="sendCodeClick">Resend
                  Code
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <div v-if="currentIndex === 1 || currentIndex === 4 " class="record_lists">
          <h3 class="ml-3 d-none d-md-block">{{ currentTitle }}</h3>
          <b-row>
            <b-col :sm="6">
              <div class="draw_down_menus mb-3">
                <div class="draw_down_title d-flex justify-content-between" @click="showDrawDownItem = !showDrawDownItem">
                  <div v-if="currentStatus">{{ currentStatus }}</div>
                  <div v-else style="color: #ccc;">Select order status</div>
                  <i class="icon icon-xiala"></i>
                </div>
                <div class="draw_down_items" v-if="showDrawDownItem">
                  <div v-for="(item , index) in optionsStatus" :key="item.value" @click="getSelectStatus(item)">{{ statusFormatts(item.value) }}</div>
                </div>
              </div>
            </b-col>
          </b-row>
          <div v-if="pcRecordLoding" class="text-center"><b-spinner variant="secondary" small label="Loading..."></b-spinner></div>
          <div v-if="recordsList.length > 0" id="navbar-example3">
            <diV v-for="(item,index) in recordsList" :key="index" class="p-2 mb-3 list_item">
              <div class="status_record" :class="'status_record' + item.status">{{ statusFormatts(item.status) }}</div>
              <b-row class="records_item">
                <b-col :lg="9">
                  <b-row v-if="currentIndex == 1">
                    <b-col :lg="6" order-sm="1" order-lg="1" order="1">Order ID: {{ item.id }}</b-col>
                    <b-col :lg="6" :sm="6" order-sm="2" order-lg="1" order="2">Recharge Amount: {{ item.rechargeAmount }} $</b-col>
                    <b-col :lg="6" :sm="12" order-sm="1" order-lg="1" order="1">Card BIN: {{ item.cardBin }}</b-col>
                    <b-col :lg="6" :sm="6" order-sm="3" order-lg="1" order="3">USDT Rate: {{ item.usdtRate }}</b-col>
                    <b-col :lg="6" :sm="6" order-sm="3" order-lg="1" order="3">Service Fee: {{ item.serviceFee }}$</b-col>
                    <b-col :lg="6" :sm="6" order-sm="4" order-lg="1"order="3" >Card Fee: {{ item.cardFee }}$</b-col>
                    <b-col :lg="6" :sm="6" order-sm="4" order-lg="1" order="3">Total Amount: {{ item.totalAmount }}$</b-col>
                    <b-col :lg="6" :sm="6" order-sm="4" order-lg="1" order="2">Pay Amount: {{ item.payUsdtAmount }} USDT</b-col>
                    <b-col :lg="6" order-sm="9" order-lg="1" order="4">Create Time: {{ item.createTime }}</b-col>
                    <b-col :lg="6" order-sm="9" order-lg="1" order="4">Expire Date: {{ item.expireDate }}</b-col>
                  </b-row>
                  <b-row v-if="currentIndex == 4">
                    <b-col :lg="12" order="1" order-lg="1">Order ID: {{ item.id }}</b-col>
                    <b-col :lg="6" order="1" order-lg="1" :sm="5" order-sm="2">Card No: {{ item.cardNo }}</b-col>
                    <b-col :lg="6" order="2" order-lg="1" :sm="7" order-sm="2">Recharge Amount: {{ item.rechargeAmount }}$</b-col>
                    <b-col :lg="6" order="2" order-lg="1" :sm="5" order-sm="3">Service Fee: {{ item.serviceFee }}$</b-col>
                    <b-col :lg="6" order="3" order-lg="1" :sm="7" order-sm="3">USDT Rate: {{ item.usdtRate }}</b-col>
                    <b-col :lg="6" order="4" order-lg="1" :sm="5" order-sm="3">Total Amount: {{ item.totalAmount }}$</b-col>
                    <b-col :lg="6" order="4" order-lg="1" :sm="7" order-sm="3">Pay Amount: {{ item.payUsdtAmount }} USDT</b-col>
                    <b-col :lg="6" order="5" order-lg="1" >Create Time: {{ item.createTime }}</b-col>
                    <b-col :lg="6" order="5" order-lg="1">Expire Date: {{ item.expireDate }}</b-col>
                  </b-row>
                </b-col>
                <b-col :lg="3">
                  <div class="mt-lg-4">
                    <b-button class="mt-2" variant="outline-secondary" block @click="checkDetail(item)">Details</b-button>
                    <b-button class="mt-2"  variant="primary" block @click="paymentrecord(item)" v-if="item.status== 0">Payment</b-button>
                    <b-button class="mt-2"  variant="primary" block @click="applicationCard(item)" v-if="(item.status == 4 || item.status== 1) && currentIndex == 1">Retry open card</b-button>
                  </div>
                </b-col>
              </b-row>
            </diV>
          
            <div class="d-none d-sm-block" ref="rpageination">
              <b-pagination v-model="recordsForm.pageNum" :total-rows="totalRecords"
                :per-page="recordsForm.pageSize" align="right" @change="changePage"></b-pagination>
            </div>
            
            <div class="d-flex d-block d-sm-none justify-content-around align-items-center bottom_text">
              <div class="line"></div>
              <b-spinner v-if="recordLoading" variant="secondary" small label="Loading..."></b-spinner>
              <p v-else-if="recordsList.length < totalRecords">Load more</p>
              <p v-else>No more</p>
              <div class="line"></div>
            </div>
            
          </div>
        </div>

        <div v-if="currentIndex === 5">
          <h3 class="ml-3 d-none d-md-block">{{ currentTitle }}</h3>
          <b-row>
            <b-col :cols="12" :md="10" :lg="6">
              <div class="m-3">
                <b-form @submit="confirmUpgradeCode">
                  <b-form-group label="Upgrade Code">
                    <b-form-input v-model="upgradeCode" placeholder="Enter Upgrade Code" required></b-form-input>
                  </b-form-group>
                  <b-button variant="primary" :disabled="!upgradeCode" block class="mt-3" type="submit">Submit Upgrade Code</b-button>
                </b-form>
      
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <b-modal id="payment-modal" hide-header hide-footer class="setting_payment_modal">
      <payment-container :showClose="true" :isRecharge="currentIndex == 4"  @closepayModal="closepayModal" :payForm="payForm" @hidePaid="hidePaid">
      </payment-container>
    </b-modal>

    <b-modal id="order-detail-modal" hide-header hide-footer style="padding-left: 0;" size="md" centered>
      <order-detail :currentOrder="currentOrder" :currentIndex="currentIndex" @hideOrderDetail="hideOrderDetail"></order-detail>
    </b-modal>
    
  </div>
</template>

<script>
  import {
    sendCode,
    checkCode,
  } from "@/api/login";
  import {
    getInfo,
    editInfo,
    resetPassword
  } from "@/api/user";
  import {
    getOpenCardRecords,
    getOrderDetail,
    postApplicationCard,
    getRechargeRecords,
    getRechargeDetail,
  } from "@/api/card";
  import Cookies from "js-cookie";
  import paymentContainer from "@/components/payment-container.vue"
  import orderDetail from "@/components/setting/order-detail.vue"
  import {applyShareCode, listShareUser, setVipUpgradeCode} from "../../api/user";

  export default {
    components: {
      paymentContainer,
      orderDetail
    },
    data() {
      return {
        emailAuth: 1,
        showAlert: false,
        showErrorAlert: false,
        showinfoAlert: 0,
        successinfoMsg: "",
        successMsg: "",
        errorMsg: "",
        hideLBtns: true,
        currentIndex: 0,
        currentTitle: "Profile",
        profileInfo: {
          img: require("@/assets/user/profile.png"),
          nickName: '',
          usdtAddress: '',
          email: '',
          code: "",
          cardCapacity: 0,
          maxCapacity: 0,
          upgradeCode: null,
          shareCode: null,
        },
        passwordForm: {
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",//确认密码
        },
        //列表参数
        recordsForm: {
          pageNum: 1,
          pageSize: 5,
          status: null,
        },
        showDrawDownItem: false,
        currentStatus: null,
        optionsStatus: [
          { value: 0 },
          { value: 1 },
          { value: 2 },
          { value: 3 },
          { value: 4 },
          { value: 5 },
          { value: null},//Reset
        ],
        payForm: {
          paymethodId: "",
          expireDate: "",
          orderNo: "",
          payUsdtAmount: "",
          rechargeAmount: "",
        },
        currentOrder: {},
        recordLoading: false,
        pcRecordLoding: false,
        recordsList: [],
        totalRecords: 0,
        smallWidth: false,
        upgradeCode: null,
        shareLink: window.location.href.toString().replace(this.$route.path,'/login?scode='),
        shareUserList: []
      }
    },
    created() {
      this.$emit('imageBg', {})
      // this.hideLBtns = false;
      // this.currentIndex = -1;
      this.getUserInfo();
      if (this.$route.query.tag == "email" && this.emailAuth == 0) {
        this.currentTitle = "Email Authentication";
        this.currentIndex = 3;
      } else if (this.$route.query.tag == "order"){
        this.btnIndex(1 , 'My card opening record');
      } else if (this.$route.query.tag == "recharge"){
        this.btnIndex(4 , 'My Recharge record');
      }
    },
    mounted(){
      window.addEventListener("scroll",this.handleScroll)
      window.addEventListener("resize",this.resizeWidth)
    },
    watch: {
      'smallWidth': function(val){
        this.resetForm();
        this.getList();
      }
    },
    methods: {
      resizeWidth(e){
        let w =  window.innerWidth || document.documentElement.clientWidth
        if( w <= 576 ){
          this.smallWidth = true;
        } else {
          this.smallWidth = false;
        }
      },
      handleScroll(e){
        let st = document.documentElement.scrollTop || document.body.scrollTop;
        let ch = document.documentElement.clientHeight;
        let sh = document.documentElement.scrollHeight;
        let w =  window.innerWidth || document.documentElement.clientWidth
        if(( st + ch + 3 >= sh ) && w <= 576 ){
          if( !this.recordLoading && this.recordsList.length < this.totalRecords){
            this.recordsForm.pageNum++;
            this.recordLoading = true;
            this.getList();
          }
        }
      },
      getList(){
        if(this.currentIndex == 1){
          this.getOpenCardList()
        } else if ( this.currentIndex == 4){
          this.getRechargeList()
        }
      },
      getUserInfo() {
        getInfo().then(res => {
          this.profileInfo.nickName = res.data.nickName;
          this.profileInfo.email = res.data.email;
          this.profileInfo.usdtAddress = res.data.usdtAddress;
          this.emailAuth = res.data.authStatus;
          this.profileInfo.maxCapacity = res.data.maxCapacity;
          this.profileInfo.upgradeCode = res.data.upgradeCode;
          this.profileInfo.shareCode = res.data.shareCode;
          if(this.profileInfo.shareCode){
            listShareUser().then(rest=>{
              this.shareUserList = rest.data;
            })
          }
        })
      },
      editUserInfo() {
        if (this.profileInfo.nickName || this.profileInfo.usdtAddress) {
          let info = {
            nickName: this.profileInfo.nickName,
            usdtAddress: this.profileInfo.usdtAddress
          }
          editInfo(info).then(res => {
            this.successinfoMsg = 'Saved successfully';
            this.showinfoAlert = 3;
            this.getUserInfo();
          })
        }
      },
      applyShareLink(){
        applyShareCode().then(res=>{
          this.getUserInfo();
        })
      },
      copyShareLink(){
        var input1 = document.createElement('input'); // 直接构建input
        input1.value = this.shareLink + this.profileInfo.shareCode; // 设置内容
        document.body.appendChild(input1);
        input1.select(); // 选择实例内容
        document.execCommand('Copy'); // 执行复制
        document.body.removeChild(input1); // 删除临时实例
        this.$bvToast.toast('Copy succeeded', {
          title: 'Tips',
          variant: 'success',
          toaster: 'b-toaster-top-center',
          solid: true,
        });
      },
      backBtn() {
        this.hideLBtns = false;
        this.currentIndex = -1;
      },
      btnIndex(index, title) {
        this.showErrorAlert = false;
        this.showinfoAlert = false;
        this.currentIndex = index;
        this.currentTitle = title;
        this.hideLBtns = true;
        if (index == 3) {
          this.sendCodeClick();
        } else if (index == 1) {
          this.currentStatus = null;
          this.recordsForm.status = null;
          this.resetForm();
          this.pcRecordLoding = true;
          this.getOpenCardList();
        } else if( index == 4){
          this.currentStatus = null;
          this.recordsForm.status = null;
          this.resetForm();
          this.pcRecordLoding = true;
          this.getRechargeList();
        }
      },
      resetForm(){
        this.recordsForm.pageNum = 1;
        this.recordsList = [];
      },
      // 发送验证码
      sendCodeClick() {
        sendCode().then(res => {
          this.$bvToast.toast( "Verification code send successfully" ,{
            title: "Operating tips",
            variant: "success",
            toaster: 'b-toaster-top-center',
            solid: true,
            autoHideDelay: 2000
          })
        }).catch(error => {
          this.errorMsg = error;
          this.showErrorAlert = true;
        })
      },
      //校验验证码
      checkCodeClick() {
        if (this.profileInfo.code) {
          checkCode(this.profileInfo.code).then(res => {
            this.emailAuth = 1;
            this.$bvToast.toast('Email authentication succeeded', {
              title: "Operating tips",
              variant: "success",
              toaster: 'b-toaster-top-right',
              solid: true
            })
            setTimeout(() => {
              this.$router.go(0);
            }, 2000)
          }).catch(err => {
            this.errorMsg = err;
            this.showErrorAlert = true;
          })
        }
      },
      confirmPsw(e){
        if( this.validationPassword && this.valiPassword ){
          resetPassword({
            newPassword: this.passwordForm.newPassword,
            oldPassword: this.passwordForm.oldPassword
          }).then( res => {
            this.$bvToast.toast('Reset succeeded', {
              title: "Operating tips",
              variant: "success",
              toaster: 'b-toaster-top-right',
              solid: true
            })
            this.passwordForm = {
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            }
          }).catch( err => {
          })
        }
        e.preventDefault()
        e.stopPropagation()
        
      },
      // 升级码
      confirmUpgradeCode(e){
        if(this.upgradeCode){
          setVipUpgradeCode(this.upgradeCode).then(res=>{
            this.$bvToast.toast('Upgrade succeeded', {
              title: "Operating tips",
              variant: "success",
              toaster: 'b-toaster-top-right',
              solid: true
            })
            this.upgradeCode = null;
          })
        }
        e.preventDefault()
        e.stopPropagation()
      },
      //获取开卡记录列表
      getOpenCardList() {
        
        getOpenCardRecords(this.recordsForm).then(res => {
          if(this.recordLoading){
            this.recordsList = this.recordsList.concat(res.rows)
          } else {
            this.recordsList = [];
            this.recordsList = res.rows;
          }
          // this.recordsList = res.rows;
          this.totalRecords = res.total;
          this.recordLoading = false;
          this.pcRecordLoding = false;
        })
      },
      //充值记录列表
      getRechargeList(){
        getRechargeRecords(this.recordsForm).then( res => {
          if(this.recordLoading){
            this.recordsList = this.recordsList.concat(res.rows)
          } else {
            this.recordsList = [];
            this.recordsList = res.rows;
          }
          this.totalRecords = res.total;
          this.recordLoading = false;
          this.pcRecordLoding = false;
      
        })
      },
      changePage(pageNum) {
        this.recordsForm.pageNum = pageNum;
        this.recordsList = [];
        this.getList();
      },
      statusFormatts(val, key, data) {
        if( val == null ) return 'Reset'
        switch (val) {
          case 0:
            return 'To be paid';
          case 1:
            return 'Paid';
          case 2:
            if(this.currentIndex == 1) return 'Card opening';
            if(this.currentIndex == 4) return 'Recharging';
            return val;
          case 3:
            if(this.currentIndex == 1) return 'Card opening succeeded';
            if(this.currentIndex == 4) return 'Recharge succeeded';
            return val;
          case 4:
            if(this.currentIndex == 1) return 'Card opening failed';
            if(this.currentIndex == 4) return 'Recharge failed';
            return val;
          case 5:
            return 'Cancelled';
          default:
            return val;
        }
      },
      getSelectStatus(item){
        this.recordsForm.status = item.value;
        if( !item.value && item.value != 0 ){
          this.currentStatus = null;
        } else {
          this.currentStatus = this.statusFormatts(item.value);
        }
        this.resetForm();
        this.showDrawDownItem = false;
        this.getList()
      },
      checkDetail(item) {
        if( this.currentIndex == 1){
          getOrderDetail(item.id).then(res => {
            this.currentOrder = res.data;
            this.$bvModal.show("order-detail-modal");
          })
        } else if( this.currentIndex == 4){
          getRechargeDetail(item.id).then(res => {
            this.currentOrder = res.data;
            this.$bvModal.show("order-detail-modal");
          })
        }
      },
      hideOrderDetail() {
        this.$bvModal.hide("order-detail-modal");
      },
      paymentrecord(item) {
        this.payForm.expireDate = item.expireDate;
        this.payForm.orderNo = item.id;
        this.payForm.payUsdtAmount = item.payUsdtAmount;
        this.payForm.rechargeAmount = item.rechargeAmount
        this.$bvModal.show('payment-modal');
      },
      applicationCard(item){
        postApplicationCard(item.id).then( res => {
          this.$bvToast.toast('Retry to open the card succeeded',{
            title: "Operating tips",
            variant: "success",
            toaster: 'b-toaster-top-center',
            solid: true,
            autoHideDelay: 2000
          })
          this.resetForm();
          this.getOpenCardList();
        }).catch( err => {
        })
      },
      hidePaid() {
        this.$bvModal.hide('payment-modal');
        this.resetForm();
        this.getList();
      },
      closepayModal() {
        this.$bvModal.hide('payment-modal');
      },
    },
    computed: {
      validationPassword() {
        if (this.passwordForm.confirmPassword) {
          return this.passwordForm.newPassword == this.passwordForm.confirmPassword
        }
      },
      valiPassword() {
        //用户密码长度必须介于 5 和 20 之间
        if (this.passwordForm.newPassword) {
          return this.passwordForm.newPassword.length >= 5 && this.passwordForm.newPassword.length <= 20
        }
      },
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('resize', this.handleScroll);
    }
  }
</script>

<style lang="scss">
  .setting_container {
    .reward_text {
      color: #47933e;
      font-size: 1.3rem;
    }

    .password_md {
      text-align: left;
      font-weight: bold;
      border: 2px solid #333;
      padding: 0 1rem;
      margin-bottom: .8rem;
    }
    
    .progress-bar {
      background-color: $theme;
    }

    .left_btns {
      .btn {
        text-align: left;
        font-weight: bold;
        border: 2px solid #333;
        padding: 0 1rem;
        margin-bottom: .8rem;
      }

      .btn_active {
        color: #fff;
        background-color: #333;
      }
    }

    .reward_content {
      .h4 {
        margin-bottom: 0;
        width: 8rem;
      }
    }

    .credit_content {
      .h4 {
        margin-bottom: 0;
        width: 8rem;
      }
    }

    .progress {
      border: 1px solid #333;
      background-color: #fff;
    }

    .confirm_code {
      .btn-outline-light {
        border: 1px solid #333;
      }

      .text {
        margin: 0.5rem 0 1rem;
        color: #6c757d;
        font-size: 0.8rem;
      }
    }

    .coinbase_content {

      .btn {
        color: #333;
        display: flex;
        padding: 0;
        border: 0;
        line-height: 2.4em;
        border: 2px solid #007bff;
        border-radius: 2px;

        .icon_btn {
          color: #fff;
          align-self: stretch;
          background-color: #007bff;
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 2.4em;

          i {
            font-size: 2rem;
          }

        }

        .right_btn {
          text-align: center;
          flex: 1;
          padding: 0 4rem;
        }

        &:hover {
          color: #fff;
        }
      }
    }
    
    .record_lists {
      .list_item {
        border: 1px solid #ccc;
        position: relative;
        border-radius: 5px;
      }
      .status_record {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #007BFF;
        color: #fff;
        border-radius: 0 0 0 15px;
        padding: 5px 10px;
        font-size: 0.8rem;
      }
      
      .status_record3, .status_record2 , .status_record1 {
        background-color: #28a745;
      }
      .status_record4 {
        background-color: #888;
      }
      .status_record5 {
        background-color: #ccc;
      }
      
      .bottom_text {
        .line {
          background-color: #ccc;
          height: 1px;
          width: 30%;
        }
        
        p {
          margin-bottom: 0;
        }
      }
      
      .draw_down_menus {
        position: relative;
        cursor: pointer;
        
        .draw_down_title {
          border: 1px solid #888;
          border-radius: 5px;
          padding: 0 1rem;
          line-height: 2.5rem;
          color: #333;
        }
        .draw_down_items {
          position: absolute;
          top: 2.5rem;
          left: 0;
          right: 0;
          background-color: #fff;
          z-index: 5;
          border: 1px solid #888;
          >div {
            padding: 0 1rem;
            line-height: 2rem;
            &:hover {
              background-color: $theme;
              color: #fff;
            }
          }
        }
      }
      
    }
    
    .avatar_img {
      width: 8rem;
      height: 8rem;
      background-color: $theme;
      border-radius: 5px;
    }

  }

  #order-detail-modal {
    .form-group {
      margin-bottom: 0;
    }

    .form-row .col-form-label {
      font-weight: bolder !important;
      text-align: right;
    }
  }
  
  .setting_payment_modal {
    padding-left: 0!important;
  }
  
  @media  screen and (max-width: 430px) {
    .record_lists {
      .records_item {
        padding-top: 1.5rem!important;
      }
    }
    
  }
</style>
